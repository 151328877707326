.app {
  width: 100%;
}
.topbar,
.navbar,
.payment-notice {
  position: relative;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.payment-notice {
  height: 200px;
  background-color: red;
  color: #fff;
  z-index: 1001;
}
.notice-content {
  text-align: center;
  margin-top: 80px;
  text-transform: uppercase;
  font-size: 1.5em;
}
.topbar {
  width: 100%;
  background-color: rgb(41, 69, 31);
  padding: 0.5em 1em;
  height: 38px;
}
.navbar {
  height: 80px;
  background-color: #fff;
  padding: 1em 0;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3);
}
.topbar-contents,
.nav-contents,
.menu-side-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.top-contacts-item {
  display: flex;
  align-items: center;
}
.top-contacts-item.mid-item {
  margin: 0 8px;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  padding: 0 8px;
}
.top-contacts-item img {
  width: 20px;
  margin-right: 4px;
}
.top-contacts-item span {
  font-size: 14px;
  color: #fff;
}
.top-social a:hover {
  background-color: rgb(14, 115, 57);
}
.top-social a.mid-item {
  margin: 0 1.5em;
}
.top-social a img {
  width: 20px;
}
.brand-side {
  display: block;
}

.brand-side img {
  width: 50px;
}
.nav-label,
.hide-menu-btn,
#toggle-responsive-menu {
  display: none;
}
.menu-side .main-menu {
  display: flex;
  cursor: pointer;
}
.menu-side .main-menu > li {
  margin-right: 2em;
}
.menu-side ul.main-menu > li > a {
  text-decoration: none;
  color: #29451f;
  font-family: "Agrandir Heavy", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-size: 12px;
}
.menu-side ul.main-menu > li > a:hover,
.menu-side ul.main-menu > li > a:focus,
.dropdown ul li > a:hover {
  color: #0e7339;
  text-decoration: underline;
}
.nav-cta {
  background-color: #fed507;
  color: #29451f;
  width: auto;
}

.dropdown {
  position: relative;
}

.dropdown ul.dropdown-menu {
  display: none;
  flex-flow: column;
  border: 1px solid #29451f;
  border-bottom: 0;
  box-shadow: 0 0 3px 1px rgba(41, 69, 31, 0.3);
  position: absolute;
  top: 100%;
  width: 130px;
  height: auto;
  background-color: #fff;
  z-index: 10;
}

.dropdown ul li {
  padding: 10px;
  border-bottom: 1px solid #29451f;
}
.dropdown ul li > a {
  text-decoration: none;
  color: #29451f;
}

.dropdown:hover ul.dropdown-menu {
  display: flex;
  animation: animatezoom 1s;
}
.hide-responsive {
  display: none;
}
.hero {
  background-color: #000;
  width: 100%;
  position: relative;
}

.carousel-content-container,
.carous .slider-items-container .carous-image img,
.hero-content-container {
  height: 80vh;
}
.carousel-content {
  position: absolute;
  z-index: 6;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.carous .slider-items-container .carous-image img {
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.carous .slider-items-container {
  padding: 0 !important;
}

.hero-content-container {
  width: 100%;
  display: flex;
}

.hero-content-inner {
  margin: auto;
  width: 300px;
  background-color: rgba(41, 69, 31, 0.8);
  padding: 12px;
  border-radius: 0;
  position: relative;
  z-index: 7;
  color: #fff;
  margin-bottom: 0;
  margin-left: 0;
  height: 100%;
}

.hero-body-body p {
  color: #eee;
}
.hero-body-head h3 {
  text-transform: uppercase;
}
.hero-body-body h3 {
  margin-bottom: 8px;
}
.welcome .hero-content-inner {
  margin-left: auto;
  background-color: transparent;
  width: 600px;
}
.welcome h1 {
  font-size: 4em;
  text-transform: uppercase;
  text-align: center;
  color: #fed507;
  text-shadow: 0 3px 2px rgba(0, 0, 0, 0.3);
}
.welcome h1 span {
  color: #fff;
  font-size: 20px !important;
  margin-bottom: 1em;
}
.ctas-container {
  justify-content: space-around;
  align-items: center;
}

.ctas-container .button {
  width: auto;
  text-transform: uppercase;
}

.subcta {
  background-color: #fff;
  color: #29451f;
}
.buttons-container {
  display: flex;
}
.buttons-container .button {
  margin: 1em;
}
.subcta-yellow {
  background-color: #fed507;
  color: #29451f;
}

.subcta-green {
  background-color: #29451f;
  color: #fff;
}
.subcta-lightgreen {
  background-color: #8ad169;
  color: #fff;
}

.hero-body ul {
  list-style: inside decimal;
}

.slider-items-container {
  height: 100%;
  width: 100%;
  padding: 0;
}

.slider-items-container .slick-arrow {
  background-color: #8ad169 !important;
  border-radius: 2px;
  height: 32px;
  width: 50px;
  top: 100%;
}
.pdt-img-container.slider-items-container .slick-arrow {
  background-color: #29451f !important;
  width: 30px;
}
.carous .slick-arrow {
  background-color: transparent;
}

.slider-items-container .slick-arrow:hover {
  background-color: #000 !important;
}

.slider-items-container.center-slide-arrows .slick-arrow {
  top: 37% !important;
}

.pdt-img-container.slider-items-container.center-slide-arrows .slick-arrow {
  top: 50% !important;
}

.slider-items-container .slick-next,
.slider-items-container .slick-prev {
  z-index: 2;
}

.slider-items-container .slick-next {
  right: 0px;
}

.slider-items-container .slick-prev {
  left: 0px;
}

.carous .slider-items-container.center-slide-arrows .slick-arrow {
  top: 90% !important;
}
.carous .slider-items-container .slick-next {
  right: 20px;
}

.carous .slider-items-container .slick-prev {
  left: 20px;
}
.slider-items-container .slick-arrow.slick-disabled:before {
  opacity: 0;
}

.slider-items-container .slick-arrow.slick-disabled {
  background-color: transparent !important;
  cursor: auto !important;
}

.about-footer {
  width: 500px;
  text-align: left;
}

.about-footer img {
  width: 50px;
  background-color: #fed507;
}

.about-footer p {
  color: #eee;
  margin-top: 20px;
  text-align: justify;
}
.footer-menu-title {
  color: #fed507;
}
.footer-menu {
  justify-content: space-between;
  align-items: flex-start;
}
.footer-nav {
  text-align: center;
}

.footer-nav li {
  margin: 10px 0;
}
.footer-nav li a {
  text-decoration: underline;
  color: #eee;
}
.footer-nav li a:hover {
  color: #fed507;
}

.textpage-inner {
  justify-content: space-between;
  align-items: flex-start;
}
.textpage-text-side {
  width: 60%;
}

.textpage-text-side h2 {
  font-size: 1.5em;
  margin-bottom: 1em;
}

.textpage-text-side h3 {
  font-size: 1.1em;
  margin: 1em 0;
  margin-bottom: 8px;
}

.textpage-text-side p {
  margin-bottom: 1em;
  font-size: 1.1em;
}

.mission-side {
  width: 30%;
  background-color: #29451f;
  color: #eee;
  padding: 0 1em;
}
.mission-item {
  border-top: 1px solid #fed507;
  display: flex;
  padding: 2em 8px;
}
.mission-item img {
  width: 40px;
}
.mission-item p {
  margin-top: 1em;
}
.mission-item h2 {
  font-size: 1.5em;
}

.textpage-text-model {
  border-left: 10px solid #29451f;
  background-color: rgba(41, 69, 31, 0.3);
  padding: 1em;
}
.results {
  background-color: #fed507;
  width: 100%;
  height: 100%;
}
.results-title {
  font-size: 1em;
  text-align: center;
}
.about-results-inner {
  justify-content: space-around;
  margin-top: 2em;
}
.about-results-item {
  background-color: #29451f;
  color: #eee;
  padding: 1em;
  border-radius: 2px;
  text-align: center;
  width: 25%;
}
.about-results-item h5 {
  font-size: 2em;
  margin-bottom: 0.5em;
}
.about-contents {
  justify-content: space-between;
  align-items: flex-start;
}
.about-text-side {
  width: 50%;
}
.about-image-side {
  width: 40%;
}
.section-head h2 {
  font-size: 3em;
}

.section-body p {
  font-size: 1.2em;
  margin: 1em 0 0 0;
}
.about-snippet {
  margin: 1em 0;
  font-size: 3em;
}
.floating-image {
  position: absolute;
  width: 400px;
  height: 200px;
  left: 60px;
  top: 90%;
  object-fit: cover;
  object-position: center;
  vertical-align: middle;
}
.initiative-card {
  width: 100%;
  padding: 1em;
  margin-bottom: 5em;
}
.initiative-card .card-body {
  justify-content: space-between;
  align-items: center;
}
.initiative-card .card-body-text {
  width: 50%;
}
.initiative-card .card-image-side {
  width: 45%;
  background-color: #fed507;
}

.ordered.initiative-card .card-image-side {
  order: -1;
}

.ordered.initiative-card .card-body-text {
  text-align: right;
}

.services-container,
.pdts-cards-container {
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
}
.service-card {
  width: 30%;
  text-align: center;
  margin-bottom: 2em;
}

.service-card > div,
.pdt-card > div {
  margin-bottom: 1em;
}
.pdt-card > div:last-child {
  margin-bottom: 0;
}
.slider-items-container.pdt-img-container {
  padding: 0 !important;
}
.service-card-image img,
.pdt-img img {
  height: 200px;
  object-fit: cover;
  object-position: center;
  vertical-align: middle;
}
.pdt-img-container,
.pdt-img img {
  height: 150px;
}
.card-cta,
.pdt-cta {
  background-color: #fed507;
  color: #29451f;
}
.pdt-cta .card-cta {
  width: 100%;
}

.pdt-card {
  width: 300px;
  text-align: center;
  background-color: #eee;
  border-radius: 2px;
  padding: 4px;
  margin-bottom: 1.2em;
}
.pdt-img {
  position: relative;
}
.pname-span {
  position: absolute;
  bottom: 0;
  top: 87%;
  right: 0;
  left: 0;
  text-align: center;
  color: #fed507;
}
.initiative-card .card-image-side {
  width: auto;
  height: auto;
}
.contacts-container {
  justify-content: space-between;
}
.contact-item,
.contact-item-body {
  text-align: center;
}
.contact-item-head {
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
}
.contact-item-head img {
  margin-right: 4px;
  width: 25px;
}
.contact-item-head h2 {
  font-size: 1em;
}
.map-container iframe {
  width: 100%;
  height: 300px;
}

.gallery-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}
.gallery-container figure {
  width: 30%;
  margin: 1em;
  border: 2px solid rgba(41, 69, 31, 0.3);
  padding: 10px;
  border-radius: 2px;
  background-color: lightyellow;
}

.gallery-container figure figcaption {
  font-size: 0.7em;
}

.farm-tour-video-container {
  width: 100%;
}

.team-container {
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
}
.team-container li {
  margin: 30px;
  width: 300px;
  border: 1px solid #29451f;
  padding: 1em;
  background-color: #8ad169;
}
.team-container li figure {
  background-color: #29451f;
}
.team-container li figure figcaption {
  text-align: center;
  font-size: 10px;
  text-transform: uppercase;
  color: #fed507;
}
.floating-button-container {
  position: fixed;
  right: 10px;
  bottom: 20%;
  z-index: 100;
  text-align: right;
  overflow: hidden;
  width: 50px;
  height: 50px;
}
.whatsapp-button {
  background-color: #25d366;
  padding: 0;
}

.whatsapp-button a {
  color: #fff !important;
  display: flex !important;
  align-items: center;
}
.whatsapp-button a img {
  line-height: 0;
  width: 50px;
  height: 50px;
}

@media screen and (min-width: 960px) and (max-width: 1200px) {
  .welcome {
    padding-top: 100px;
  }
  .buttons-container {
    justify-content: center;
  }
  .service-card {
    width: 40%;
  }
  .gallery-container figure {
    min-width: 40%;
    max-width: 40%;
  }
  .top-contacts {
    width: 50%;
    flex-flow: column;
  }
  .topbar {
    height: 95px;
    padding: 0.5em 0;
  }
 
  .top-contacts-item.mid-item {
    margin: 8px 0;
    padding: 0;
    border: 0;
  }

  .top-social a img {
    width: 30px;
  }
  .initiative-card .card-image-side {
    display: none;
  }
  .initiative-card .card-body-text {
    width: 100%;
  }
  .about-image-side {
    display: none;
  }
  .about-text-side {
    width: 80%;
    margin: auto;
    text-align: center;
  }
}
@media screen and (min-width: 800px) and (max-width: 959px) {
  .welcome {
    padding-top: 100px;
  }
  .buttons-container {
    justify-content: center;
  }
  .top-contacts {
    width: 50%;
    flex-flow: column;
  }
  .topbar {
    height: 95px;
    padding: 0.5em 0;
  }
  .notice-content {
    margin-top: 55px;
  }
  .top-contacts-item.mid-item {
    margin: 8px 0;
    padding: 0;
    border: 0;
  }

  .top-social a img {
    width: 25px;
  }
  .menu-side .main-menu > li {
    margin-right: 1em;
  }
  .about-image-side {
    display: none;
  }
  .about-text-side {
    width: 80%;
    margin: auto;
    text-align: center;
  }
  .about-results-item h5 {
    font-size: 1.5em;
    margin-bottom: 0.5em;
  }
  .about-results-item {
    width: 30%;
  }
  .footer-menu {
    flex-flow: row wrap;
    justify-content: center;
  }
  .footer-menu-item {
    order: -1;
    width: 30%;
  }
  .footer-menu-item.about-footer {
    order: 1;
    width: 60%;
    margin-top: 2em;
  }
  .footer-menu-item.about-footer img {
    text-align: center;
    margin: auto;
    display: block;
  }
  .footer-menu-title,
  .about-footer p {
    text-align: center;
  }
  .service-card {
    width: 40%;
  }
  .width-960 {
    width: 100%;
  }
  .page-hero {
    margin-top: 170px;
  }
  .textpage-text-side {
    width: 65%;
  }
  .mission-side {
    padding: 0 0.1em;
  }
  .initiative-card {
    padding-top: 0;
  }
  .initiative-card .card-body {
    flex-flow: column;
  }
  .initiative-card .card-body-text,
  .ordered.initiative-card .card-body-text,
  .initiative-card .card-image-side {
    width: 100%;
    text-align: center;
  }
  .initiative-card .card-image-side {
    order: -1;
    margin: 1em 0;
    height: 200px;
    display: none;
  }
  .initiative-card .card-image-side img {
    object-fit: cover;
    object-position: center;
  }
  .gallery-container figure {
    min-width: 40%;
    max-width: 40%;
  }
}
@media screen and (min-width: 600px) and (max-width: 799px) {
  .welcome {
    padding-top: 100px;
  }
  .buttons-container {
    justify-content: center;
  }
  .welcome .hero-content-inner {
    width: 100%;
  }
  .welcome h1 {
    font-size: 3em;
  }
  .top-contacts {
    width: 50%;
    flex-flow: column;
  }
  .topbar {
    height: 95px;
    padding: 0.5em 0;
  }
  .notice-content {
    margin-top: 60px;
    font-size: 1.2em;
  }
  .top-contacts-item.mid-item {
    margin: 8px 0;
    padding: 0;
    border: 0;
  }
  .menu-side .main-menu > li {
    margin-right: 1em;
  }
  .about-image-side {
    display: none;
  }
  .about-text-side {
    width: 80%;
    margin: auto;
    text-align: center;
  }
  .about-results-item h5 {
    font-size: 1.5em;
    margin-bottom: 0.5em;
  }
  .about-results-item {
    width: 30%;
  }
  .footer-menu {
    flex-flow: row wrap;
    justify-content: center;
  }
  .footer-menu-item {
    order: -1;
    width: 30%;
  }
  .footer-menu-item.about-footer {
    order: 1;
    width: 90%;
    margin-top: 2em;
  }
  .footer-menu-item.about-footer img {
    text-align: center;
    margin: auto;
    display: block;
  }
  .footer-menu-title,
  .about-footer p {
    text-align: center;
  }
  .service-card {
    width: 40%;
  }
  .width-960 {
    width: 100%;
  }
 
  .textpage-text-side {
    width: 65%;
  }
  .mission-side {
    padding: 0 0.1em;
    width: 100%;
    margin: 2em 0;
  }
  .initiative-card {
    padding-top: 0;
  }
  .initiative-card .card-body {
    flex-flow: column;
  }
  .initiative-card .card-body-text,
  .ordered.initiative-card .card-body-text,
  .initiative-card .card-image-side {
    width: 100%;
    text-align: center;
  }
  .initiative-card .card-image-side {
    order: -1;
    margin: 1em 0;
    height: 200px;
  }
  .initiative-card .card-image-side img {
    object-fit: cover;
    object-position: center;
  }
  .menu-side-inner {
    flex-flow: column;
    background-color: #fff;
    width: 150px;
    padding: 8px;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
  }
  .main-menu {
    display: flex;
    flex-flow: column;
  }
  .menu-side .main-menu > li {
    margin: 1em 0;
  }
  .hide-responsive {
    display: block;
  }
  .nav-cta {
    margin-top: 1em;
    width: 100%;
  }
  .dropdown ul {
    right: 50%;
  }
  .nav-label {
    display: block;
    cursor: pointer;
  }
  .showParent {
    display: flex;
    animation: animatezoom 1s;
  }
  .hide-menu-btn {
    padding: 0;
    box-shadow: none;
    display: flex;
  }
  .pdt-card {
    width: 250px;
  }
  .textpage-inner,
  .contacts-container {
    flex-flow: column;
  }
  .textpage-text-side {
    width: 70%;
    margin: auto;
    text-align: justify;
  }
  .contact-item {
    margin-bottom: 2em;
  }
  .title {
    font-size: 24px;
  }
  .width-800 {
    width: 100%;
  }
  .gallery-container figure {
    min-width: 40%;
    max-width: 40%;
    margin: 0.5em;
  }
  .initiative-card .card-image-side,
  .ordered.initiative-card .card-image-side {
    display: none;
  }
}
@media screen and (max-width: 599px) {
  .container {
    padding: 0 0.3em;
  }
  .notice-content {
    margin-top: 40px;
    font-size: 1em;
  }
  .topbar,
  .navbar {
    position: relative;
  }

  .topbar-contents {
    flex-flow: column;
  }
  .top-contacts {
    width: 100%;
    flex-flow: column;
  }
  .top-contacts-item span {
    font-size: 12px;
  }
  .topbar {
    height: 125px;
    padding: 0.5em 0;
    display: none;
  }
  .top-social {
    margin-top: 8px;
  }
  .navbar,
  .hero,
  .page-hero {
    margin-top: 0px;
  }
  .top-contacts-item.mid-item {
    margin: 8px 0;
    padding: 0;
    border: 0;
  }
  .menu-side .main-menu > li {
    margin-right: 1em;
  }
  .hide-responsive {
    display: block;
  }
  .section-head h2 {
    font-size: 2em;
  }
  .about-image-side {
    display: none;
  }
  .about-text-side {
    width: 100%;
    margin: auto;
    text-align: center;
  }
  .width-800 {
    width: 100%;
  }
  .about-results-item h5 {
    font-size: 1.5em;
    margin-bottom: 0.5em;
  }
  .about-results-inner {
    flex-flow: column;
  }
  .about-results-item {
    width: 100%;
    margin-bottom: 1em;
  }
  .footer-menu {
    flex-flow: row wrap;
    justify-content: center;
  }
  .footer-menu-item {
    order: -1;
    width: 100%;
    margin-bottom: 1em;
  }
  .footer-menu-item.about-footer {
    order: 1;
    width: 90%;
    margin-top: 2em;
  }
  .footer-menu-item.about-footer img {
    text-align: center;
    margin: auto;
    display: block;
  }
  .footer-menu-title,
  .about-footer p {
    text-align: center;
  }
  .service-card {
    width: 100%;
  }
  .width-960 {
    width: 100%;
  }

  .textpage-text-side {
    width: 65%;
  }
  .mission-side {
    padding: 0 0.1em;
    width: 100%;
    margin: 2em 0;
  }
  .initiative-card {
    padding-top: 0;
  }
  .initiative-card .card-body {
    flex-flow: column;
  }
  .initiative-card .card-body-text,
  .ordered.initiative-card .card-body-text,
  .initiative-card .card-image-side {
    width: 100%;
    text-align: center;
  }
  .initiative-card .card-image-side {
    order: -1;
    margin: 1em 0;
    height: 200px;
  }
  .initiative-card .card-image-side img {
    object-fit: cover;
    object-position: center;
  }
  .menu-side-inner {
    flex-flow: column;
    background-color: #fff;
    width: 150px;
    padding: 8px;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
  }
  .main-menu {
    display: flex;
    flex-flow: column;
  }
  .menu-side .main-menu > li {
    margin: 1em 0;
  }
  .nav-cta {
    margin-top: 1em;
    width: 100%;
  }
  .dropdown ul {
    right: 50%;
  }
  .nav-label {
    display: block;
    cursor: pointer;
  }
  .showParent {
    display: flex;
    animation: animatezoom 1s;
  }
  .hide-menu-btn {
    padding: 0;
    box-shadow: none;
    display: flex;
  }
  .pdt-card {
    width: 300px;
  }
  .textpage-inner,
  .contacts-container {
    flex-flow: column;
  }
  .textpage-text-side {
    width: 95%;
    margin: auto;
    text-align: center;
  }
  .contact-item {
    margin-bottom: 2em;
  }
  .title {
    font-size: 24px;
    text-align: center;
  }
  .gallery-container figure {
    width: 100%;
  }
  .initiative-card .card-image-side,
  .ordered.initiative-card .card-image-side {
    display: none;
  }
  .about-snippet {
    font-size: 2em;
  }
  .hero-content-inner {
    width: 200px;
  }
  .hero-body-head h1 {
    font-size: 1.3em;
  }
  .welcome {
    padding-top: 50px;
  }
  .buttons-container {
    justify-content: center;
  }
  .welcome .hero-content-inner {
    width: 100%;
  }
  .welcome h1 {
    font-size: 2em;
  }
  .team-container li {
    margin: 12px 10px;
    width: 300px;
  }
  .floating-button-container {
    margin-right: 10%;
    margin-bottom: 30%;
    overflow: hidden;
  }
}
